.gallery {
  .lozad {
    cursor: pointer;
    transition: filter 0.3s;

    &:hover {
      filter: brightness(70%);
    }
  }
}

@media (max-width: $screen-medium) {
  .gallery {
    .lozad {
      &:hover {
        filter: none;
      }

      &:active {
        filter: brightness(70%);
      }
    }
  }
}