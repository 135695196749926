.nav {
  background-color: $color-white;
  width: 100%;
  z-index: 10;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
  display: block;
  border-bottom: 1px $color-grey solid;

  &-items {
    display: inline-block;
    vertical-align: middle;
    padding: 0 8px;

    .logo {
      height: $nav-height;
      width: auto;
    }
  }

  &-item {
    @extend .u-align-y--flex-middle;
    display: block;
    color: $color-black;
    text-align: center;
    margin: 0 0.25rem;
    padding: 0 0.5rem;
    text-decoration: none;
    cursor: pointer;

    & > * {
      padding: 1rem 0;
    }

    &:hover:not(.active) {
      color: $theme-colour;
    }
  }

  .dropdown {
    overflow: hidden;

    &:hover .dropdown-content {
      display: block;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    top: 64px;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: $text-colour;
    padding: 0.75rem 1rem;
    text-decoration: none;
    display: block;
    text-align: left;

    &:hover:not(.active) {
      color: $theme-colour;
    }
  }

  &-menu {
    @extend h3;
    @extend .u-align-y--flex-middle;
    margin: 0;
    height: $nav-height;
    float: right;
    color: darken($theme-colour, 15%);
    padding: 0.5rem 1rem;
    border-radius: 4px;
  }
}

.nav-expanded {
  width: 100%;
  z-index: 99;
  position: fixed;
  top: 116px;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: all 0.3s;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, .2);
  @include make-scrollable(y);
  background-color: $background-colour;


  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

.nav-item-mobile {
  display: block;
  text-decoration: none;
  color: $color-black;
  width: 100%;
  text-align: center;
  padding: 1.5rem 2rem;
  border-top: 1px solid $color-grey;

  &:hover {
    color: $theme-colour;
  }
}

.contact-bar {
  background-color: $theme-colour;
}