.header {
  background-image: linear-gradient(90deg, darken($theme-colour, 15%), $theme-colour);
}

.header--dash {
  position: relative;

  .text {
    background-color: $background-colour;
    width: auto;
    padding-right: 0.25rem;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    content: '';
    background: $color-grey;
    z-index: -1;
  }

  &--white {
    &::after {
      border: 2px solid $color-white;
    }
  }
}

.overlay {
  position: absolute;
  background-color: rgba($color-black, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
}