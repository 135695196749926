.input-container {
  position: relative;
  border-bottom: 2px solid $color-grey;
  margin-bottom: 32px;
}

input {
  display: block;
  width: 100%;
  height: 2.5rem;
  background: transparent;
  padding: 0 2px;
  outline: none;
  border: none;
}

input:focus + .input-focus:before {
  width: 100%;
}

.input-focus:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  background: $theme-colour;
  background: linear-gradient(45deg, darken($theme-colour, 20%), $theme-colour);
}

.input-focus {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}