.sticky {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  z-index: 10;

  &--top {
    top: 0;
  }

  &--nav {
    top: 7.125rem;
  }

  &--bottom {
    bottom: 0;
  }
}