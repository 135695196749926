.form {
  position: relative;

  .pending, .success, .fail {
    display: none;
  }

  &_pending {
    .pending {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($background-colour, 0.5);
      left: 0;
      top: 0;
      z-index: 1;

      .spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        border: 0.5rem solid $background-colour;
        border-top: 0.5rem solid $theme-colour;
        border-bottom: 0.5rem solid $theme-colour;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        transform: translate(-50%, -50%);
        color: $text-colour;
        animation: spin 2s linear infinite;
      }
    }
  }

  &_success {
    .success {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $background-colour;
      left: 0;
      top: 0;
      z-index: 1;

      & > .message {
        position: absolute;
        padding: 1rem;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &_fail {
    .fail {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $background-colour;
      left: 0;
      top: 0;
      z-index: 1;

      & > .message {
        position: absolute;
        padding: 1rem;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}