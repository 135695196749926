// Page Widths
// Variables are required for each width of the page at specific break points
$screen-small: 544px;
$screen-medium: 812px;
$screen-large: 992px;
$screen-x-large: 1200px;

$nav-height: 4rem;

// Colours
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #cfd7eb;
$cavern-pink: #DFC0BD;
$color-cod-grey: #1e1e1e;
$color-blue: #0397f5;
$color-green: #2ecc71;
$color-red: #e74c3c;


$background-colour: #F5F5F5;
$text-colour: rgba(0, 0, 0, 0.76);
$theme-colour: #3498db;
