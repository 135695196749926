.testimony {
  padding: 1rem;

  &--text {
    border-left: 1px solid $color-grey;
    padding-left: 1.5rem;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -2px;
      transform: translateY(-50%);
      width: 3px;
      height: 30px;
      background: $theme-colour;
    }
  }
}