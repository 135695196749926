/*
  / ____|    (_)   | |
 | |  __ _ __ _  __| |
 | | |_ | '__| |/ _` |
 | |__| | |  | | (_| |
  \_____|_|  |_|\__,_|
*/
//=============================================================
/*
  Makes the margin for a grid based on a gutter size

  Only apply margin to the left and top of the grid.

  This is due to a property of negative margins where
  a bottom negative margin will drag adjacent elements
  upwards in the flow of the page.

  We can then space the gutters of the the grid
  appropriately using the left and right margins,
  while keeping it flush with the container it sits in.
 */
@mixin make-margins($g) {
    margin-top: -#{$g};
    margin-left: -#{$g};

    // Apply gutters
    & > [class^="col-"] {
        margin-top: $g;
        margin-left: $g;
    }
}

/*
  Makes column width based on the total number of columns,
  the gutter size, and the specified column size
 */
@mixin make-col-width($i, $g: 0, $c: 12) {
    @if $g != 0 {
        width: calc(#{(100% / $c) * $i} - #{$g});
    } @else {
        width: #{(100% / $c) * $i};
    }
}

/*
  Makes a grid with the specified gutters and columns

  Usage: @include make-grid(16px, 12px, 12);

  Parameters:
    $gutter-size         (default: 0)            - The size of the gutter
    $gutter-size-small   (default: $gutter-size) - The size of the gutter at a medium sized screen
    $cols                (default: 12)           - The number of columns in which to divide up the grid
 */
@mixin make-grid($gutter-size: 0, $gutter-size-small: $gutter-size, $cols: 12) {
    @include make-flexbox();
    @include make-flex-direction(row);
    @include make-flex-wrap(wrap);

    // Make sure the Grid always sits flush within its parent container
    position: relative;

    @if $gutter-size != 0 {
        @include make-margins($gutter-size);
    }

    // Set column width
    @for $i from 1 through $cols {
        & > .col-#{$i} {
            @include make-col-width($i, $gutter-size);
            z-index: 1;
        }
    }

    // Default column width breakpoint
    @media (max-width: $screen-medium) {
        // Make all columns 100% width on small screens by default
        @for $i from 1 through $cols {
            & > .col-#{$i} {
                @include make-col-width(12, $gutter-size);
            }
        }
    }

    // Update the gutters if a different mobile size is provided
    @media (max-width: $screen-small) {
        // Make all columns 100% width on small screens by default
        @for $i from 1 through $cols {
            & > .col-#{$i} {
                @include make-col-width(12, $gutter-size-small);
            }
        }

        @include make-margins($gutter-size-small);
    }

    // Sets column width at the extra large screen breakpoint
    @media (max-width: $screen-x-large) {
        @for $i from 1 through $cols {
            & > .col-#{$i}-xl {
                @include make-col-width($i, $gutter-size);
                // Updates the gutter size on small screen breakpoints
                @media (max-width: $screen-small) {
                    @include make-col-width($i, $gutter-size-small);
                }
            }
        }
    }

    // Sets column width at the large screen breakpoint
    @media (max-width: $screen-large) {
        @for $i from 1 through $cols {
            & > .col-#{$i}-l {
                @include make-col-width($i, $gutter-size);
                // Updates the gutter size on small screen breakpoints
                @media (max-width: $screen-small) {
                    @include make-col-width($i, $gutter-size-small);
                }
            }
        }
    }

    // Sets column width at the medium screen breakpoint
    @media (max-width: $screen-medium) {
        @for $i from 1 through $cols {
            & > .col-#{$i}-m {
                @include make-col-width($i, $gutter-size);
                // Updates the gutter size on small screen breakpoints
                @media (max-width: $screen-small) {
                    @include make-col-width($i, $gutter-size-small);
                }
            }
        }
    }

    // Sets column width at the small screen breakpoint
    @media (max-width: $screen-small) {
        @for $i from 1 through $cols {
            & > .col-#{$i}-s {
                @include make-col-width($i, $gutter-size-small);
            }
        }
    }
}

// Standard grid
.grid {
    @include make-grid(2rem, 1.5rem);

    &--small {
        @include make-grid(1rem, 1rem);
    }
}

// Grid with no gutters
.grid-compact {
    @include make-grid();
}