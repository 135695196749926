* {
  box-sizing: border-box;
}

html {
  background-color: $background-colour;
  /* Disable tap overlay color on links - set alpha to %0 = invisible */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  @include make-flexbox();
  @include make-flex-direction(column);
  @include make-flex-grow(1);
  -webkit-font-smoothing: antialiased;
}

hr {
  margin: 0;
  padding: 0;
  clear: both;
  border: none;
  border-top: 1px $color-cod-grey solid;

  &.light {
    border-top: 1px $color-grey solid;
  }
}

main {
  // Grow on pages with little content to ensure the footer is always at the bottom
  @include make-flex-grow(1);
}

section {
  // Fixes overlapping margin not expanding the section height
  display: inline-block;
  width: 100%;
}

.section {
  &--white {
    background-color: $color-white;
  }

  &--theme {
    background-color: darken($theme-colour, 10%);
  }

  &--gradient {
    background: linear-gradient($color-white, rgba(lighten($theme-colour, 5%), 0.11));
  }
}

p {
  margin: 1.5rem 0;
}

.container {
  max-width: $screen-x-large;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;

  &--large {
    max-width: $screen-large;
  }

  &--medium {
    max-width: $screen-medium;
  }

  &--small {
    max-width: $screen-small + 80px;
  }
}

.block {
  margin-top: 5rem;
  margin-bottom: 5rem;

  &__content {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: $screen-medium) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .block {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}