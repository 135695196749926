// Default text styles
html {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: $text-colour;
  font-size: 1rem;
}

body {
  font-size: 1.2rem;
  line-height: 1.5;
}

@for $i from 1 through 5 {
  h#{$i}, .h#{$i} {
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.2;
    margin: 0 0 1.5rem 0;
    color: $color-black;
    text-transform: uppercase;
  }
}

h1, .h1 {
  font-size: 3rem;
  line-height: normal;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.125rem;
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.9rem;
}

.label {
  font-size: 0.75rem;
  color: $color-cod-grey;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.link {
  color: $color-blue;
  transition: text-decoration-color .15s;
  user-select: none;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.fineprint {
  font-size: 0.694rem;
  vertical-align: middle;
}

@media (max-width: $screen-medium) {
  h1 {
    font-size: 2.25rem;
  }
}