.card {
  transition: all 0.5s;
  border-radius: 0.5rem;
  overflow: hidden;
  background: $background-colour;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);
}

.card-header {
  padding: 2rem 1rem;
  background-color: $theme-colour;
}

.card--white {
  background: $background-colour;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);
}

.card-image {
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
}