.icon {
  font-size: 3rem;
  color: $color-white;

  &--small {
    font-size: 1rem;
  }

  &--medium {
    font-size: 4rem;
  }

  &--container {
    line-height: 1.3;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;

    &--theme {
      background: $theme-colour;
    }

    &--small {
      width: 42px;
      height: 42px;
    }
  }

  &--success {
    color: $color-green;
  }

  &--fail {
    color: $color-red;
  }

  &--dash {
    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      content: '';
      z-index: 1;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 10px;
      border-color: transparent transparent transparent #e1e8ff;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      content: '';
      width: 100%;
      height: 1px;
      background: #e1e8ff;
      z-index: -1;
    }
  }

  &--name {
    background: linear-gradient(126deg, darken($theme-colour, 15%), $theme-colour);
    text-shadow: 0 0 2px rgba(0, 0, 0, .15);
    color: #fff;
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  &--theme {
    color: $theme-colour;
  }
}