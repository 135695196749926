img, video, iframe {
  // Fixes alignment and padding for adjacent elements
  vertical-align: middle;
  border: none;
  width: 100%;
}

.img {
  background-size: cover;
  background-position: center;
  width: 100%;

  & + label {
    margin-top: 0.5rem;
  }

  &--16-9 {
    padding-top: 56.25%;
  }

  &--1-1 {
    padding-top: 100%;
  }

  &--2-1 {
    padding-top: 50%;
  }

  &--4-3 {
    padding-top: 75%;
  }

  &--3-2 {
    padding-top: 66%;
  }

  &--contain {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.lozad {
  opacity: 0;
  transition: opacity 0.3s;

  &--loaded {
    opacity: 1;

    img {
      opacity: 1;
    }
  }
}

.map {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
  height: 288px;
}
