// Spacing classes
$props: (
        p: padding,
        m: margin
);

$sides: (
        t: top,
        b: bottom,
        l: left,
        r: right
);

$spacing: (
        0: 0,
        1: 0.25rem,
        2: 0.5rem,
        3: 0.75rem,
        4: 1rem,
        5: 1.25rem,
        6: 1.5rem,
        7: 2rem,
        8: 2.5rem,
        9: 3rem
);

@each $sp_key, $sp_val in $spacing {
  @each $prop_key, $prop_val in $props {
    .#{$prop_key}-#{$sp_key} {
      #{$prop_val}: #{$sp-val} !important;
    }
    @each $side_key, $side_val in $sides {
      .#{$prop_key}#{$side_key}-#{$sp_key} {
        #{$prop_val}-#{$side_val}: #{$sp-val} !important;
      }
    }
  }
}