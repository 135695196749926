.button {
  @extend h4;
  height: 2.7rem;
  margin: 0;
  line-height: normal;
  color: $color-white;
  background: $theme-colour;
  padding: 0.72rem 1.75rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border-radius: 100px;
  border: none;
  position: relative;
  display: inline-block;
  min-width: 10.5rem;
  letter-spacing: 0.0625rem;
  box-shadow: 0 0 1px rgba($color-black, 0.04), 0 2px 6px rgba($theme-colour, 0.12), 0 10px 20px rgba($theme-colour, 0.18);

  transition: box-shadow 0.3s, background-color 0.3s, transition 0.3s;

  &--wide {
    min-width: 14rem;
  }

  &--full {
    width: 100%;
  }

  // Extend the anchor tag  clickbox to cover the whole button
  & > a:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
  }

  &:hover, &:focus {
    background-color: darken($theme-colour, 5%);
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 0 1px rgba($color-black, 0.04), 0 0 2px rgba($theme-colour, 0.12), 0 4px 8px rgba($theme-colour, 0.18);
  }

  &--secondary {
    background: $theme-colour;
    border: 1px solid $color-white;

    // Extend the anchor tag  clickbox to cover the whole button
    &:hover, &:focus {
      background-color: darken($theme-colour, 5%);
    }

    &:active {
      transform: scale(0.99);
      box-shadow: 0 0 1px rgba($color-black, 0.04), 0 0 2px rgba($theme-colour, 0.12), 0 4px 8px rgba($theme-colour, 0.18);
    }
  }
}