.accordion {
  cursor: pointer;
  padding: 1.5rem 2rem;
  width: 100%;
  outline: none;
}

.accordion:after {
  margin: 0 0 0 0.5rem;
  content: '\002B';
  font-weight: 700;
  float: right;
}

.active:after {
  content: "\2212";
}

.panel {
  padding: 0 1rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: $color-white;

  ul {
    height: unset;
  }

  .nav-item-mobile{
    border: none;
  }
}