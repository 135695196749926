.u-align--center {
  text-align: center;
}

.u-white-space--nowrap {
  white-space: nowrap;
}

.u-align-y--middle {
  vertical-align: middle;
}

.u-position--relative {
  position: relative !important;
}

.u-align-self--center {
  align-self: center;
}

.u-justify-content--left {
  @include make-justify-content(flex-start);
}

.u-justify-content--center {
  @include make-justify-content(center);
}

.u-justify-content--right {
  @include make-justify-content(flex-end);
}

.u-justify-content--space-between {
  @include make-justify-content(space-between);
}

.u-flex-wrap--wrap {
  @include make-flex-wrap(wrap);
}

.u-display-flex {
  @include make-flexbox(!important);
}

.u-flex-direction-column {
  @include make-flex-direction(column);
}

.u-align-y--flex-middle {
  @include make-flexbox(!important);
  @include make-align-items(center);
}

.u-clearfix:after {
  @include make-clearfix;
}

.u-display-block {
  display: block !important;
}

.u-display-none {
  display: none !important;
}

.u-overflow-x {
  @include make-scrollable(x);
}

.u-overflow-y {
  @include make-scrollable(y);
}

.u-overflow-visible {
  overflow: visible;
}

.u-only-mobile {
  display: none !important;
}

.u-text-white {
  color: $color-white !important;
}

.u-text-black {
  color: $color-black !important;
}

@media (max-width: $screen-medium) {
  .u-only-mobile {
    display: inherit !important;
  }

  .u-only-desktop {
    display: none !important;
  }
}