.swiper-container {
  opacity: 0;
  transition: opacity 0.3s;

  .swiper-pagination {
    &.swiper-pagination-bullets {
      bottom: 0;
    }
  }
}

.swiper-container.carousel {
  height: fit-content;

  .swiper-slide {
    transition: filter 0.3s;
    filter: brightness(50%);
  }

  .swiper-slide-active {
    filter: brightness(100%);
  }

  .swiper-pagination {
    &.swiper-pagination-bullets {
      bottom: 10px;

      .swiper-pagination-bullet {
        background-color: $color-white;
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }
}